import { ReactNode } from 'react';
import _ from 'lodash';
import numeral from 'numeral';
import moment from "moment";
import queryString from "query-string";
import { DateObject } from 'react-multi-date-picker';
import { parseISO, getUnixTime, format } from 'date-fns';
import { toast } from 'react-toastify';
import humanizeDuration from 'humanize-duration';
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import isValidCoordinates from "is-valid-coordinates";

import { ReactComponent as InfoIcon } from "../assets/icons/info.svg";
import { ReactComponent as SuccessIcon } from "../assets/icons/success.svg";
import { ReactComponent as WarningIcon } from "../assets/icons/warning.svg";
import { ReactComponent as DangerIcon } from "../assets/icons/danger.svg";

import axios from './axios';
import axiosAuth from './axiosAuth';

import { LOGIN_PATH, STORAGE_KEY, STORAGE_EXPIRES_AT, STORAGE_USER, STORAGE_USER_ACCOUNT } from './enums';

const CHARACTERS = ['\\', ':', ';', '*', '?', '"', '<', '>', '|',];
const ALPHABETIC_CHARACTERS = ['\\', ':', ';', '*', '?', '"', '<', '>', '|', '/', '.', ',', '\'', '[', ']', '{', '}', '!', '@', '#', '$', '%', '^', '&', '(', ')', '_', '=', '+'];

const { REACT_APP_IS_PRODUCTION } = process.env;

// Auth ----------------------------------------------------------------------

export const isProduction = () => {
  return (REACT_APP_IS_PRODUCTION == 'true') ? true : false;
};

export const isValidToken = (accessToken: any, token_expires_at: any) => {
  if (!accessToken) {
    return false;
  }

  const exp = getUnixTimestamp(token_expires_at);
  const currentTime = getUnixTimestamp(moment().toISOString());
  const isValid = (exp > currentTime);
  
  return isValid;
};

export const tokenExpired = (exp: any) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    console.info('Token expired');

    localStorage.removeItem(STORAGE_KEY);

    window.location.href = LOGIN_PATH;
  }, timeLeft);
};

export const setSession = (accessToken: any, token_expires_at: any, user: any, shouldSetUserData: boolean) => {
  if (accessToken) {
    localStorage.setItem(STORAGE_KEY, accessToken);
    localStorage.setItem(STORAGE_EXPIRES_AT, token_expires_at);

    const exp = getUnixTimestamp(token_expires_at);

    tokenExpired(exp);

    if(shouldSetUserData){
      let userAccount = (user && user.userAccount) ? user.userAccount : null;
      localStorage.setItem(STORAGE_USER, JSON.stringify(user));
      localStorage.setItem(STORAGE_USER_ACCOUNT, JSON.stringify(userAccount));
    }
  } else {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(STORAGE_EXPIRES_AT);
    localStorage.removeItem(STORAGE_USER);
    localStorage.removeItem(STORAGE_USER_ACCOUNT);
    localStorage.clear();

    delete axiosAuth.defaults.headers.common['Accept-Language'];
    delete axiosAuth.defaults.headers.common.Authorization;

    delete axios.defaults.headers.common['Accept-Language'];
    delete axios.defaults.headers.common.Authorization;
  }
};

export const getUnixTimestamp = (token_expires_at: any) => {
  const parsedDatetime = parseISO(token_expires_at);
  return getUnixTime(parsedDatetime);
};

export const getDateFromTimestamp = (ts: any) => {
  const date = new Date(ts * 1000);
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};

export const getUser = () => {
  try {
    const userStr: any = localStorage.getItem(STORAGE_USER);
    const user = JSON.parse(userStr);
    return user;
  } catch(e){
    return null;
  }
};
export const getTimeZone = () => {
  try {
    let user: any = getUser();
    let userAccount = (user && user.userAccount) ? user.userAccount : null;
    let timezoneUtcOffset = (userAccount && isNumeric(userAccount.timezoneUtcOffset)) ? userAccount.timezoneUtcOffset : 0;
    return timezoneUtcOffset;
  } catch(e){
    return 0;
  }
};

export const getCurrency = () => {
  return 'S$';
};

// API ----------------------------------------------------------------------

export interface ReadAPIParams {
  currentPage?: number|null,
  pageSize?: number|null,
  sortColumn?: any|null,
  sortDir?: string|null,
  searchQuery?: string|null,
  isIncludeInactive?: boolean|null,
  isInactive?: boolean|null,
}

export const getMaxPageSize = () => {
  return 99999;
};
export const getDefaultPageSize = (x: number = 10) => {
  return x;
};
export const getMinDate = () => {
  return '0001-01-01T00:00:00';
};
export const getMaxDate = () => {
  return '9999-12-31T23:59:59';
};

export const successAPI = (data: any) => {
  if(typeof data === 'object'){
    let message = (data && data.message && data.message != '') ? data.message : null;
    if(typeof message === 'string'){
      success('Success', message);
    }
  }
};
export const errorAPI = (error: any) => {
  if(typeof error === 'object'){
    let err = (error && error.error) ? error.error : null;
    danger('Error', err);
  } else if(typeof error === 'string'){
    danger('Error', error);
  }
};

// Toast ----------------------------------------------------------------------

export const info = (title: string|null, desc: string|ReactNode|null = null) => {
  toast.info(<>
    {title && <div className='title'>{title}</div>}
    {desc && <div>{desc}</div>}
  </>, { icon: <><InfoIcon /></> });
}
export const success = (title: string|null, desc: string|ReactNode|null = null) => {
  toast.success(<>
    {title && <div className='title'>{title}</div>}
    {desc && <div>{desc}</div>}
  </>, { icon: <><SuccessIcon /></> });
}
export const warning = (title: string|null, desc: string|ReactNode|null = null) => {
  toast.warning(<>
    {title && <div className='title'>{title}</div>}
    {desc && <div>{desc}</div>}
  </>, { icon: <><WarningIcon /></> });
}
export const danger = (title: string|null, desc: string|ReactNode|null = null) => {
  toast.error(<>
    {title && <div className='title'>{title}</div>}
    {desc && <div>{desc}</div>}
  </>, { icon: <><DangerIcon /></> });
}

// querystring ----------------------------------------------------------------------

export const getIntProps = (props: any = null, field: string = 'id') => {
  let id = null;

  if (props == null || props[field] == null) {
      let q: any = queryString.parse(window.location.search);
      id = (q[field]) ? parseInt(q[field]) : 0;
  } else {
      id = (props[field]) ? props[field] : null;
  }

  return id;
}
export const getStringProps = (props: any = null, field: string = 'name') => {
  let name = '';

  if (props == null || props[field] == null) {
      let q: any = queryString.parse(window.location.search);
      name = (q[field]) ? decodeURIComponent(q[field]) : '';
  } else {
      name = (props[field]) ? decodeURIComponent(props[field]) : '';
  }

  return name;
}
export const getBoolProps = (props: any = null, field: string = 'isCopy') => {
  let state: any = '';
  let param = 'false';

  if (props == null || props[field] == null) {
      let q: any = queryString.parse(window.location.search);
      param = (q[field]) ? decodeURIComponent(q[field]) : 'false';
  } else {
      param = (props[field]) ? decodeURIComponent(props[field]) : 'false';
  }


  if (param.toLowerCase().trim() == 'true') {
      state = true;
  } else if (param.toLowerCase().trim() == 'false') {
      state = false;
  } else {
      state = false;
  }

  return state;
}

export const setTextProps = (param: string = '', text: string = '') => {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set(param, text);
  return { search: queryParams.toString() }
}
export const getTextProps = (param: string = '', fallback: string = '') => {
  let q: any = queryString.parse(window.location.search);
  let text = (q[param]) ? decodeURIComponent(q[param]) : '';
  return (text && text != '') ? text : fallback;
}

// Table ----------------------------------------------------------------------

export const flashById = (id: string, anim = 'flash-update') => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      try {
        let el = document.getElementById("row_" + id);
        el?.classList.add(anim);
        setTimeout(() => {
          try {
            el?.classList.remove(anim);
          } catch (err) {}

          resolve();
        }, 1500);
      } catch (err) {}
    }, 500);
  });
};
export const flash = (item: any, id: string, anim = 'flash-update') => {
  return new Promise<void>((resolve) => {
    if(item instanceof Array){
      item.forEach((itm, i) => {
        setTimeout(() => {
          try {
            let el = document.getElementById("row_" + itm[id]);
            el?.classList.add(anim);
            setTimeout(() => {
              try {
                el?.classList.remove(anim);
              } catch (err) {}

              resolve();
            }, 1500);
          } catch (err) {}
        }, 500);
      });
    } else {
      setTimeout(() => {
        try {
          let el = document.getElementById("row_" + item[id]);
          el?.classList.add(anim);
          setTimeout(() => {
            try {
              el?.classList.remove(anim);
            } catch (err) {}

            resolve();
          }, 1500);
        } catch (err) {}
      }, 500);
    }
  });
};
export const removeRow = async (rows: Array<any>, id: string, item: any) => {
  let newRows = _.cloneDeep(rows);

  if(item instanceof Array){
    newRows = newRows.filter((row: any) => !item.some((x) => x[id] === row[id]));
  } else {
    newRows = newRows.filter((x: any) => x[id] !== item[id]);
  }

  await flash(item, id, 'flash-remove');
  return newRows;
}
export const createRow = (rows: Array<any>, id: string, item: any) => {
  let newRows = _.cloneDeep(rows);
  newRows.unshift(item);
  flash(item, id, 'flash-create');
  return newRows;
}
export const updateRow = (rows: Array<any>, id: string, item: any) => {
  let newRows = _.cloneDeep(rows);

  if(item instanceof Array){
    item.forEach((itm) => {
      const index = newRows.findIndex((x: any) => x[id] === itm[id]);
      if (index !== -1) {
        newRows.splice(index, 1, itm);
      }
    });

  } else {
    const index = newRows.findIndex((x: any) => x[id] === item[id]);
    if(index !== -1){
      newRows.splice(index, 1, item);
    }
  }

  flash(item, id, 'flash-update');
  return newRows;
}
export const updateField = (rows: Array<any>, id: string, item: any, field: any, value: any) => {
  let newRows = _.cloneDeep(rows);

  const index = newRows.findIndex((x: any) => x[id] === item[id]);
  if(index !== -1){
    let newItem = _.cloneDeep(item);
    newItem[field] = value;
    newRows.splice(index, 1, newItem);
  }

  return newRows;
}
export const getIds = (rows: any = [], field = 'id') => {
  let ids = '';

  if(rows && rows.length > 0){
    ids = rows.map((x: any) => x[field]).join(',');
  }

  return ids;
}
export const cloneOriginal = (rows: any = [], fields: any) => {
  let newRows: any = [];

  if(rows && rows.length > 0){
    let rowsClone = _.cloneDeep(rows);
    newRows = rowsClone.map((x: any) => {
      Object.keys(fields).forEach(key => {
        x.original[key] = fields[key];
      });
      return x.original;
    });
  }

  return newRows;
}

// Download ----------------------------------------------------------------------

export const isBase64 = (url: string) => {
  return /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+)?;base64,/.test(url);
};
export const getBase64 = (file: any) => {
  const base64 = file?.url?.split(',')[1];
  const mimeType = file?.url?.split(';')[0]?.split(':')[1];

  return {
    base64: base64,
    mimeType: mimeType,
  }
}
export const getUint8Array = (base64: any) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return new Uint8Array(byteNumbers);
}
export const downloadPDF = (name: string, byte: any, type = "application/pdf") => {
  let byteArray = getUint8Array(byte);
  let blob = new Blob([byteArray], { type });
  let url = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
export const viewPDF = (byte: any) => {
  let byteArray = getUint8Array(byte);
  const blob = new Blob([byteArray], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  window.open(url, '_blank');
}
export const downloadCSV = (name: string, byte: any, type = "text/csv") => {
  let blob = new Blob([byte], { type });
  let url = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
export const downloadEXCEL = (name: string, byte: any, type = "application/vnd.ms-excel") => {
  let byteArray = getUint8Array(byte);
  let blob = new Blob([byteArray], { type });
  let url = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
export const downloadImage = (file: any) => {
  let byteArrayPromise;
  let mimeType = 'application/octet-stream';

  if (isBase64(file.url)) {
    let base64Data = getBase64(file);
    byteArrayPromise = Promise.resolve(getUint8Array(base64Data?.base64));
    mimeType = base64Data?.mimeType || 'application/octet-stream';
  } else {
    byteArrayPromise = fetch(file.url)
      .then((response) => {
        mimeType = response.headers.get('Content-Type') || 'application/octet-stream';
        return response.arrayBuffer();
      })
      .then((buffer) => new Uint8Array(buffer));
  }
  
  byteArrayPromise.then((byteArray) => {
    const blob = new Blob([byteArray], { type: mimeType  });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}

// Generate PDF ----------------------------------------------------------------------

export const generatePDF = (name: string = 'Document', rows: any, columns: any, columnOrder: any, columnsRender: any) => {
  let orientation: any = 'portrait';
  let unit: any = 'pt';
  let size: any = 'A4';
  let margin: any = 40;

  const doc = new jsPDF(orientation, unit, size);
  doc.setProperties({ title: name });
  
  const exportData = getExportData(rows, columns, columnOrder, columnsRender);
  const header = exportData.header;
  const body = exportData.body;

  doc.text(name, margin, margin);
  sliceData(20, header, body, doc)

  doc.save(name + ".pdf");
}
export const sliceData = (maxContentRow: any, header: any, bodySlice: any, doc: any) => {
  let count = 0
  let bodyTemplete: any = [] 
  let bodyReady: any = []
  let margin  = 10 

  bodySlice.map((data: any, i: any)=>{
    if (count <= maxContentRow  ) {
      bodyTemplete.push(bodySlice[i]);
      if (count === maxContentRow) {
        bodyTemplete.push(bodySlice[i+1]);
        bodyReady.push(bodyTemplete);
        count = count +1;
      } else if (i === bodySlice.length - 1) {
        bodyReady.push(bodyTemplete);
        count = count +1;
      } else {
        count = count +1;
      }
    } else {
      bodyTemplete = [];
      count = 0;
      count = count +1 ;
    }
  })
  
  return (
    bodyReady.map((resultBody: any, i: any)=>{
      if (i !== 0){
        margin = margin + 900
      } else {
        margin = 10
      }

      return managePrintLayout( header, resultBody, doc, margin)
    })
  )
}
export const managePrintLayout = (header: any, body: any, doc: any, thisMargin: any) => {
  let sliceValue = 6;

  let count = 1;

  let dataHolding: any = [];
  let RealData: any = [];

  let mainData: any = [];
  let mainDataHolding: any = [];

  let valueY = 0;
  let valueX = 6;

  let margin: any = 40;

  header.map((rowId: any, i: any) => {
    if (count < sliceValue  ) {
      dataHolding.push(header[i]);
      count = count +1 ;
      if (count === sliceValue) {
        dataHolding.push(header[i+1]);
        RealData.push(dataHolding);
      } else if (i === header.length - 1) {
        RealData.push(dataHolding);
      }
    } else {
      dataHolding = [];
      count = 0;
      count = count +1 ;
    }
  });

  for (let index = 0; index < RealData.length; index++) {
    body.map((jobsData: any, i: any) => {
      if(jobsData){
        let bodySlcie = jobsData.slice((valueY ), valueX);
        mainDataHolding.push(bodySlcie);
        if (i === (body.length - 1)) {
          mainData.push(mainDataHolding);
          mainDataHolding = [];
          valueY = valueY + (sliceValue );
          valueX = valueX + (sliceValue  +1);
        }
      }
    });
  }

  return RealData.map((rowId: any, i: any) => {
    return (
      doc.autoPrint(),
      doc.autoTable(RealData[i], mainData[i], {
        startY: i === 0 ? margin + thisMargin : margin + 900,
        styles: {
          fontSize: 8,
          minCellWidth: 60,
          minCellHeight: 10,
          margin: 40,
        },
      })
    )
  });
}
export const getExportData = (rows = [], columns = [], columnOrder: any, columnsRender: any) => {
  const header = [];
  const headerCSV = [];
  let body = [];
  let groups: any = [];

  if (columnOrder && columnOrder.length > 0) {
    for (let c = 0; c < columnOrder.length; c++) {
      let index = columns.findIndex((x: any) => x.id === columnOrder[c]);

      if(index > -1){
        let coll: any = columns[index];
  
        if (coll?.getIsVisible() && (coll.id != 'mrt-row-select') && (coll.id != 'mrt-row-expand')) {
          let colName = (coll.columnDef && coll.columnDef.header) ? coll.columnDef.header : coll.id ? coll.id : "";
          header.push(colName);
          headerCSV.push(colName);
        }
      }
    }
  }

  body = putExportData(rows, columns, columnOrder, columnsRender);

  return {
    header: header,
    headerCSV: headerCSV,
    body: body,
    groups: groups,
  };
}
export const putExportData = (array: any, columns: any, columnOrder: any, columnsRender: any) => {
  let body = [];

  if (array && array.length > 0) {
    for (let r = 0; r < array.length; r++) {
      let row = array[r];

      let rowArr = [];
      if (columnOrder && columnOrder.length > 0) {
        for (let c = 0; c < columnOrder.length; c++) {
          let indexColumns = columns.findIndex((x: any) => x.id === columnOrder[c]);
          if(indexColumns > -1){
            let coll = columns[indexColumns];
  
            if (coll?.getIsVisible() && coll.id != 'mrt-row-select') {
              if(columnsRender && columnsRender.length > 0){
                let index = columnsRender.findIndex((x: any) => x.id === coll.id);
  
                if(coll && coll.columnDef && index > -1){
                  if(coll.columnDef.PDF){
                    let value = coll?.columnDef?.PDF(row);
                    rowArr.push(value);
                  } else {
                    let value = row[coll.id];
                    rowArr.push(value);
                  }
                } else {
                  let value = row[coll.id];
                  rowArr.push(value);
                }
              } else {
                let value = row[coll.id];
                rowArr.push(value);
              }
            }
          }
        }
      }

      if (rowArr.length > 0) {
        body.push(rowArr);
      }
    }
  }

  return body;
}

// Escape chars ----------------------------------------------------------------------

export const escapeChar = (e: any = null, char: any = null) => {
  if (e && char) {
      if (char === e.key) {
          e.preventDefault();
          e.stopPropagation();
          return true;
      }
  }

  return false;
}

export const escapeChars = (e: any = null) => {
  if (e) {
      if (CHARACTERS.includes(e.key)) {
          return escapeChar(e, e.key);
      }
  }

  return false;
}
export const escapeCharsInStringOnPaste = (str: any = '') => {
  let cleanedText = str;
  CHARACTERS.forEach((character) => {
      cleanedText = cleanedText.split(character).join('');
  });
  return cleanedText;
}

export const escapeAlphabeticChars = (e: any = null) => {
  if (e) {
      if (ALPHABETIC_CHARACTERS.includes(e.key)) {
          return escapeChar(e, e.key);
      }
  }

  return false;
}
export const escapeAlphabeticCharsInStringOnPaste = (str: any = '') => {
  let cleanedText = str;
  ALPHABETIC_CHARACTERS.forEach((character) => {
      cleanedText = cleanedText.split(character).join('');
  });
  return cleanedText;
}
// DOM ----------------------------------------------------------------------

export const scrollTo = (selector: string = '', time: number = 100) => {
  setTimeout(() => {
    const element = document.querySelector(selector);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, time);
}
export const autoFoucus = (selector: string = '', time: number = 100) => {
  setTimeout(() => {
    const element = document.querySelector(selector) as HTMLInputElement;
    if (element) {
      element.focus();
    }
  }, time);
}
export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(() => info('Copied to clipboard', text));
};
export const clickOutside = (el = 'body') => {
  try {
    setTimeout(() => {
      const element: any = document.querySelector(el);
      element.click();
    }, 200);
  } catch (e) {}
};

// Format ----------------------------------------------------------------------

export const dateTimeViewFormat = () => {
  return 'MMM DD, yyyy HH:mm';
}
export const dateViewFormat = () => {
  return 'MMM DD, yyyy';
}
export const dateTimeFormat = () => {
  return 'DD-MM-YYYY HH:mm';
}
export const dateFormat = () => {
  return 'DD-MM-YYYY';
}
export const timeFormat = () => {
  return 'HH:mm';
}
export const meridiemFormat = () => {
  return 'HH:mm a';
}
export const apiDateFormat = () => {
  return 'YYYY-MM-DD';
}

export const getDateObjectTime = (time: any) => {
  try {
    return new DateObject().set({ hour: moment(time, timeFormat()).hour(), minute: moment(time, timeFormat()).minute() })
  } catch (e) {
    return new DateObject();
  }
}
export const formatBytes = (numb: number|null = 0, format = '0.0 b') => {
  try {
    return numeral(numb).format(format);
  } catch (e) {
    return numb;
  }
}
export const formatNumber = (numb: number|null = 0, format = '0,0.00', suffix = '', prefix = '') => {
  try {
    return prefix + numeral(numb).format(format) + suffix;
  } catch (e) {
    return numb;
  }
}
export const formatDistance = (numb: number|null = 0, format = '0,0.00', suffix = ' km', prefix = '') => {
  try {
    return formatNumber(numb, format, suffix, prefix);
  } catch (e) {
    return numb;
  }
}
export const formatCurrency = (numb: number|null = 0, format = '0,0.00', suffix = '', prefix = 'S$') => {
  try {
    return formatNumber(numb, format, suffix, prefix);
  } catch (e) {
    return numb;
  }
}
export const formatPhoneNumnber = () => {
  return "069/99-99-999";
}
export const formatMinToHours = (min = null) => {
  let numb = convertMinToHours(min);
  return formatNumber(numb, '0.[00]', ' h');
}
export const formatTime = (firstDate: any, secondDate: any) => {
  try {
    if(firstDate != null && secondDate != null){
      let timeMS = getTimeDiff(firstDate, secondDate);
      return humanizeDuration(timeMS);
    } else {
      return '';
    }
  } catch (e) {
    return '';
  }
}

export const round = (value: any, precision: number = 2) => {
  try {
    const numb = parseFloat(value);
    if (!Number.isNaN(numb)) {
      let res = Number(numb.toFixed(precision));
      return res;
    } else {
      return 0;
    }
  } catch(e){
    return 0;
  }
};

// Array ----------------------------------------------------------------------

export const removeEmptyRows = (array: any = [], fields: any = []) => {
  try {
    return array.map((value: any) => {
      let row = fields.map((f: any) => {
        return value[f]
      }).filter((e: any) => {
        return e === 0 ? '0' : e
      });
      return (row && row.length > 0) ? value : null;
    }).filter((x: any) => x !== null)
  }catch(e){
    return [];
  }
}

// Date ----------------------------------------------------------------------

export const createDateAsUTC = (date: any) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}
export const convertDateToUTC = (date: any) => { 
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
}
export const concatDateTime = (d: any, t: any) => {
  try {
    let date = moment(d, dateFormat());
    let time = moment(t, timeFormat());
    let dt = date.format(apiDateFormat()) + ' ' + time.format(meridiemFormat());
    let format = apiDateFormat() + ' ' + meridiemFormat();
    let newDateTime = moment(dt, format).toDate();
    let dateTime = createDateAsUTC(newDateTime).toISOString();
    let dateTimeWithoutZ = dateTime.replace('Z', '');

    return dateTimeWithoutZ;
  }catch(e){
    return null;
  }
}
export const getTimeZoneFromSettings = (date: any = null, offset: any = null) => {
  let timezoneUtcOffset = offset ? offset : getTimeZone();
  let newDate = convertDateToUTC(date ? date : new Date())
  if(isNumeric(timezoneUtcOffset)){
    return moment(newDate).add(timezoneUtcOffset, "minutes");
  } else {
    return moment(newDate).add(0, "minutes");
  }
}
export const getTodayDate = () => {
  try {
    return getTimeZoneFromSettings(new Date()).toDate();
  }catch(e){
    return undefined;
  }
}

export const getDateRanges = (exclude: any = ['Yesterday', 'Last 6 Months', 'This Year', 'Last Year']) => {
  let arr = [
    {
      label: 'Today',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().toDate(),
          endDate: moment().toDate(),
        }
      },
    },
    {
      label: 'Tomorrow',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().add(1, 'days').toDate(),
          endDate: moment().add(1, 'days').toDate(),
        }
      },
    },
    {
      label: 'Yesterday',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().subtract(1, 'days').toDate(),
          endDate: moment().subtract(1, 'days').toDate(),
        }
      },
    },
    {
      label: 'This Week',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().startOf('week').toDate(),
          endDate: moment().endOf('week').toDate(),
        }
      },
    },
    {
      label: 'Last Week',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().subtract(1, 'week').startOf('week').toDate(),
          endDate: moment().subtract(1, 'week').endOf('week').toDate(),
        }
      },
    },
    {
      label: 'This Month',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().startOf('month').toDate(),
          endDate: moment().endOf('month').toDate(),
        }
      },
    },
    {
      label: 'Last Month',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().subtract(1, 'month').startOf('month').toDate(),
          endDate: moment().subtract(1, 'month').endOf('month').toDate(),
        }
      },
    },
    {
      label: 'Last 6 Months',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().subtract(5, 'month').startOf('month').toDate(),
          endDate: moment().endOf('month').toDate(),
        }
      },
    },
    {
      label: 'This Year',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().startOf('year').toDate(),
          endDate: moment().endOf('year').toDate(),
        }
      },
    },
    {
      label: 'Last Year',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().subtract(1, 'year').startOf('year').toDate(),
          endDate: moment().subtract(1, 'year').endOf('year').toDate(),
        }
      },
    },
  ]

  if (exclude && exclude.length > 0) {
    arr = arr.filter(x => !exclude.includes(x.label))
  }

  return arr;
}
export const getDefaultRangeDate = () => {
  let item: any = {
    startDate: null,
    endDate: null,
    key: 'selection'
  };

  try {
    item.startDate = moment().startOf('week').toDate();
    item.endDate = moment().endOf('week').toDate();
  }catch(e){
    item.startDate = null;
    item.endDate = null;
  }

  return item;
}
export const getSelectedRangeDate = (selected: any) => {
  if(selected && selected.length > 0){
    let selectedRange = selected[0];

    return { 
      fromDate: (selectedRange && selectedRange.startDate) ? moment(selectedRange.startDate).format(apiDateFormat()) : null,
      toDate: (selectedRange && selectedRange.endDate) ? moment(selectedRange.endDate).format(apiDateFormat()) : null,
    }
  }

  return null;
}

export const getTimeDiff = (firstDate: any, secondDate: any) => {
  try {
    return secondDate.diff(firstDate);
  }catch(e){
    return null;
  }
}

export const saveDate = (date: any) => {
  try {
    let value = (date && date !== '') ? date : '';
    let dateValue = createDateAsUTC(moment(value, dateFormat()).toDate());
    return (value !== '') ? dateValue : null;
  }catch(e){
    return null;
  }
}
export const prepareDate = (date: any) => {
  try {
    let value = (date && date !== '') ? date : '';
    let dateValue = moment(date).format(dateFormat());
    return (value !== '') ? dateValue : '';
  }catch(e){
    return '';
  }
}
export const viewDate = (date: any) => {
  try {
    let value = (date && date !== '') ? date : '';
    let dateValue = moment(date).format(dateViewFormat());
    return (value !== '') ? dateValue : '';
  }catch(e){
    return '';
  }
}
export const saveDateTime = (date: any) => {
  try {
    let value = (date && date !== '') ? date : '';
    let dateValue = createDateAsUTC(moment(value, dateTimeFormat()).toDate());
    return (value !== '') ? dateValue : null;
  }catch(e){
    return null;
  }
}
export const prepareDateTime = (date: any) => {
  try {
    let value = (date && date !== '') ? date : '';
    let dateValue = moment(date).format(dateTimeFormat());
    return (value !== '') ? dateValue : '';
  }catch(e){
    return '';
  }
}
export const viewDateTime = (date: any) => {
  try {
    let value = (date && date !== '') ? date : '';
    let dateValue = moment(date).format(dateTimeViewFormat());
    return (value !== '') ? dateValue : '';
  }catch(e){
    return '';
  }
}

export const isDateRangeEqual = (fromDate: any, toDate: any, fromDateVal: any, toDateVal: any) => {
  try {
    return fromDate === fromDateVal && toDate === toDateVal;
  } catch(e){
    return false;
  }
}

// Convert ----------------------------------------------------------------------

export const convertMinToHours = (min: any = null) => {
  let numb = 0;

  if(min){
    try {
      numb = (parseInt(min, 10) / 60);
    } catch (e) {
      numb = 0;
    }
  } else {
    numb = 0;
  }

  return numb;
}
export const convertHoursToMin = (h: any = null) => {
  let numb = 0;

  if(h){
    try {
      numb = (parseFloat(h) * 60);
    } catch (e) {
      numb = 0;
    }
  } else {
    numb = 0;
  }

  return numb;
}

// Validation ----------------------------------------------------------------------

export const twoDecimalValidation = (value: any) => {
  if (!value) return true;
  return /^\d+(\.\d{1,2})?$/.test(value);
}

export const isNumeric = (value: any) => {
  return !isNaN(parseFloat(value)) && isFinite(value)
}

// Google Map ----------------------------------------------------------------------

export const convertCoordToLatLng = (coordinates: any, defaultCoordinates: any = null) => {
  let coord = null;

  try {
    if(coordinates && coordinates != ''){
      let coordAr = coordinates.trim().split(',');

      if (coordAr && coordAr.length == 2) {
        let lat = parseFloat(coordAr[0].replaceAll(' ', '').trim());
        let lng = parseFloat(coordAr[1].replaceAll(' ', '').trim());

        coord = { lat, lng };
      }
    }
  } catch (e) {}

  return (coord) ? coord : defaultCoordinates;
}
export const convertLatLngToCoord = (lat: any, lng: any, separator: any = ',') => {
  let coord = [];

  try {
    if (lat != "") {
      coord.push(lat);
    }

    if (lng != "") {
      coord.push(lng);
    }
  } catch (e) {}

  return (coord.length > 1 && coord[0] && coord[1]) ? coord.join(separator) : '';
}
export const convertPositionToLatLng = (position: any) => {
  let coord = null;

  try {
    coord = { lat: position.lat(), lng: position.lng() };
  }catch(e){
    try {
      coord = { lat: position.lat, lng: position.lng };
    }catch(e){}
  }

  return coord;
}

export const validateCoordinates = (coordinates: any) => {
  try {
    let args = (coordinates && coordinates != '') ? coordinates.split(',') : [];

    let latitude = (args && args.length > 0) ? (/^-?\d*\.?\d*$/.test(args[0].toString().trim())) ? parseFloat(args[0].toString().trim()) : '' : '';
    let longitude = (args && args.length > 1) ? (/^-?\d*\.?\d*$/.test(args[1].toString().trim())) ? parseFloat(args[1].toString().trim()) : '' : '';

    return (isValidCoordinates.latitude(latitude) && isValidCoordinates.longitude(longitude))
  } catch (e) {
    return false;
  }
}
export const validateLatLng = (lat: any, lng: any) => {
  try {
    let latitude = (/^-?\d*\.?\d*$/.test(lat.toString().trim())) ? parseFloat(lat.toString().trim()) : '';
    let longitude = (/^-?\d*\.?\d*$/.test(lng.toString().trim())) ? parseFloat(lng.toString().trim()) : '';

    return (isValidCoordinates.latitude(latitude) && isValidCoordinates.longitude(longitude))
  } catch (e) {
    return false;
  }
}
export const dinamicZoom = (map: any, lat: any, lng: any, extendBy = 0.0010) => {
  try {
      let bounds = new window.google.maps.LatLngBounds();
      let centerCoordinate = new window.google.maps.LatLng(lat, lng);
      bounds.extend(centerCoordinate);
      
      bounds.extend(new window.google.maps.LatLng(centerCoordinate.lat() + extendBy, centerCoordinate.lng() + extendBy));
      bounds.extend(new window.google.maps.LatLng(centerCoordinate.lat() - extendBy, centerCoordinate.lng() - extendBy));
      
      map.fitBounds(bounds);
  } catch(e){}
}
export const dinamicMultiZoom = (map: any, positions: any) => {
  try {
    let bounds = new window.google.maps.LatLngBounds();
    positions.forEach((marker: any) => {
      bounds.extend(marker.getPosition());
    });
    map.fitBounds(bounds);
  } catch(e){}
}
export const getAddressPlace = (place: any) => {
  let obj = {
    address: '',
    lat: null,
    lng: null,
    coord: '',
  }

  try {
    if(place){
      if(place.formatted_address){
        obj.address = place.formatted_address;
      } else if(place.name){
        obj.address = place.name;
      }
      
      if(place.geometry && place.geometry.location){
        obj.lat = place.geometry.location.lat();
        obj.lng = place.geometry.location.lng();
        obj.coord = convertLatLngToCoord(obj.lat, obj.lng);
      }
    }
  } catch(e){}

  return obj;
}
export const getPolygonCenterPosition = (positions: any) => {
  try {
    let bounds = new window.google.maps.LatLngBounds();
    positions.forEach((pos: any) => {
      bounds.extend(new window.google.maps.LatLng(pos.lat, pos.lng));
    });

    var lat = bounds.getCenter().lat();
    var lng = bounds.getCenter().lng();

    return { lat: lat, lng: lng };
  } catch(e){}

  return null
}
export const getPolygonCenterCoordinate = (positions: any) => {
  let coord = '';
  
  try {
    let obj: any = getPolygonCenterPosition(positions);
    return convertLatLngToCoord(obj.lat, obj.lng);
  } catch(e){}

  return coord
}
export const isMarkerExist = (array: any, position: any) => {
  try {
    if(position && array && array.length > 0){
      for (var i = 0, l = array.length; i < l; i++) {
        let lat = array[i].position.lat();
        let lng = array[i].position.lng();
        if (lat === position.lat && lng === position.lng) {
          return true;
        }
      }
    }
  } catch(e){}
  return false;
}
export const isInPolygon = (coordinates: any, position: any) => {
  let isInPoly = false;
  try {
    if(position && coordinates && coordinates.length > 0){
      let point = new window.google.maps.LatLng(position.lat, position.lng);
      let poly = new window.google.maps.Polygon({ paths: coordinates });
      isInPoly = window.google.maps.geometry.poly.containsLocation(point, poly);
    }
  } catch(e){}
  return isInPoly;
}
export const getLocationInGoogleMapURL = (lat: any, lng: any) => {
  return 'https://www.google.com/maps?q=' + lat + ',' + lng;
}