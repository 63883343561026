import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

import liveSlice from './liveSlice';

import confirmSlice from '../components/modal/confirmSlice';
import downloadSlice from '../components/download/downloadSlice';
import createGenericSlice from '../components/table/withSlice/tableSlice';

import loginSlice from '../pages/auth/slice';
import vehiclesSlice from '../pages/vehicles/slice';
import connectSlice from '../pages/vehicles/connect/connectSlice';
import driversSlice from '../pages/drivers/slice';
import fuelSlice from '../pages/vehicleMaintenance/fuel/slice';
import fuelSettingsSlice from '../pages/vehicleMaintenance/fuel/settings/slice';
import tireSlice from '../pages/vehicleMaintenance/tire/slice';
import tireSettingsSlice from '../pages/vehicleMaintenance/tire/settings/slice';
import checklistSlice from '../pages/vehicleMaintenance/checklist/slice';
import checklistSettingsSlice from '../pages/vehicleMaintenance/checklist/settings/slice';


export const Tables = {
  Driver: 'tableDriver',
  Fuel: 'tableFuel',
  Tire: 'tableTire',
  Checklist: 'tableChecklist',
}


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


const store = configureStore({
  reducer: {
    liveSlice: liveSlice.reducer,

    confirmSlice: confirmSlice.reducer,
    downloadSlice: downloadSlice.reducer,

    tableDriver: createGenericSlice<any>(Tables.Driver).reducer,
    tableFuel: createGenericSlice<any>(Tables.Fuel).reducer,
    tableTire: createGenericSlice<any>(Tables.Tire).reducer,
    tableChecklist: createGenericSlice<any>(Tables.Checklist).reducer,
    
    loginSlice: loginSlice.reducer,
    
    vehiclesSlice: vehiclesSlice.reducer,
    connectSlice: connectSlice.reducer,
    driversSlice: driversSlice.reducer,
    fuelSlice: fuelSlice.reducer,
    fuelSettingsSlice: fuelSettingsSlice.reducer,
    tireSlice: tireSlice.reducer,
    tireSettingsSlice: tireSettingsSlice.reducer,
    checklistSlice: checklistSlice.reducer,
    checklistSettingsSlice: checklistSettingsSlice.reducer,
  },
});

export { store };

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch = () => useDispatch<AppDispatch>();
